/* * @Author: liuzhixiang * @Date: 2021-02-08 12:05:42 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-09-We 05:40:02 */

<template>
  <div class="practice" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="练习记录"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- <div
      style="
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: #fff;
      "
    >
      <el-select
        v-model="selectVal"
        @change="handleChange"
        placeholder="请选择"
        size="small"
        style="width: 100%"
      >
        <el-option
          v-for="item in courseList"
          :key="item.CourseId"
          :label="item.CourseName"
          :value="item.CourseId + ',' + item.IsActive"
        >
        </el-option>
      </el-select>
    </div>
    <div class="Icons">
      <div v-for="it in practices" :key="it.id" @click="gotoList(it.id)">
        <i :class="it.icon" style="font-size: 30px"></i>
        <span>{{ it.name }}</span>
      </div>
    </div> -->
    <div class="top">
      <div class="sliding_box">
        <el-scrollbar>
          <ul>
            <li
              v-for="(item, index) in courseList"
              :key="index"
              @click="handleChange(item)"
              :class="item.CourseId == courseId ? 'active' : ''"
            >
              {{ item.CourseName }}
            </li>
          </ul>
        </el-scrollbar>
      </div>
      <ul class="practice" v-if="selectVal.includes('英语')">
        <li
          v-for="(par, i) in practices"
          :key="i"
          :class="practicesIndex == par.id ? 'active' : ''"
          @click="practicesClick(par)"
          v-if="selectVal.includes('英语') && par.id > 4"
        >
          <div :class="practicesIndex == par.id ? 'bgc_E2EBFE' : ''">
            <i
              :class="
                practicesIndex == par.id
                  ? par.icon + ' c_3A78F9'
                  : par.icon + ' c_707070'
              "
              color="#E2EBFE"
              v-if="par.icon"
            ></i>
            <img
              v-else
              :src="
                require('@/assets/images/' +
                  (practicesIndex == par.id ? par.img + '_checked' : par.img) +
                  '.png')
              "
              width="24"
              height="24"
              alt=""
            />
          </div>
          <p :class="practicesIndex == par.id ? 'active' : ''">
            {{ par.name }}
          </p>
        </li>
      </ul>
      <ul class="practice" v-if="!selectVal.includes('英语')">
        <li
          v-for="(par, i) in practices"
          :key="i"
          :class="practicesIndex == par.id ? 'active' : ''"
          @click="practicesClick(par)"
          v-if="!selectVal.includes('英语') && par.id <= 4"
        >
          <div :class="practicesIndex == par.id ? 'bgc_E2EBFE' : ''">
            <i
              :class="
                practicesIndex == par.id
                  ? par.icon + ' c_3A78F9'
                  : par.icon + ' c_707070'
              "
              color="#E2EBFE"
            ></i>
          </div>
          <p :class="practicesIndex == par.id ? 'active' : ''">
            {{ par.name }}
          </p>
        </li>
      </ul>
    </div>
    <div class="rollUp" v-if="practicesIndex <= 4">
      <div class="rollUp_top" v-if="practicesIndex == 3">
        <el-scrollbar>
          <div class="li_box">
            <span
              v-for="(rl, i) in tabsList"
              :key="i"
              :class="contactsIndex == i ? 'active' : ''"
              @click="contactsClick(rl, i)"
              >{{ rl.label }}
            </span>
          </div>
        </el-scrollbar>
      </div>
      <div class="rollUp_cont" v-if="practicesIndex != 4">
        <div v-if="list.length > 0">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="practicesType"
          >
            <el-row
              type="flex"
              justify="space-between"
              align="middle"
              v-for="(li, i) in list"
              :key="i"
            >
              <el-col :span="16">
                <div class="name">
                  {{
                    practicesIndex == 1
                      ? li.ChapterName
                      : practicesIndex == 2
                      ? li.PaperName
                      : practicesIndex == 3
                      ? li.Name
                      : ""
                  }}
                </div>
                <div
                  class="count"
                  v-if="practicesIndex == 1 || practicesIndex == 2"
                >
                  {{ li.CorrectQuestionCount }}/{{ li.AllQuestionCount }}题<span
                    style="margin-left: 10px"
                    >{{ li.QustionTime | dataYMDhms }}</span
                  >
                </div>
                <div
                  v-if="practicesIndex == 3"
                  v-html="li.Explain"
                  class="explain"
                ></div>
                <div
                  class="count_pbf"
                  v-if="practicesIndex == 1 || practicesIndex == 2"
                >
                  <el-progress
                    :percentage="
                      (li.CorrectQuestionCount / li.AllQuestionCount) * 100
                    "
                    :color="customColor"
                  ></el-progress>
                </div>
              </el-col>
              <el-col :span="8">
                <el-button
                  round
                  size="mini"
                  type="primary"
                  @click="gotoAnalysis(li)"
                  >查看详情</el-button
                >
              </el-col>
            </el-row>
          </van-list>
        </div>
        <div v-else>
          <el-row>
            <el-col :span="24">
              <no-data></no-data>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="list" v-if="practicesIndex == 4">
        <div class="li">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="practicesType"
            v-if="list.length > 0"
          >
            <!-- <div class="title">{{ i + 1 }}、{{ li.QuestionTitle }}</div> -->
            <div v-for="(lb, b) in list" :key="b" class="paperBank">
              <div class="title">{{ lb.ChapterName }}</div>
              <div class="questionTitle">
                <p class="tmtype">
                  <span
                    :class="
                      lb.QuestionCategory == 0
                        ? 'bgc_3A78F9'
                        : lb.QuestionCategory == 1
                        ? 'bgc_FBB03B'
                        : lb.QuestionCategory == 2
                        ? 'bgc_00AE62'
                        : lb.QuestionCategory == 3
                        ? 'bgc_B938B5'
                        : lb.QuestionCategory == 4
                        ? 'bgc_9682EC'
                        : 'bgc_37E6CF'
                    "
                  >
                    {{
                      lb.QuestionCategory == 0
                        ? "单选"
                        : lb.QuestionCategory == 1
                        ? "多选"
                        : lb.QuestionCategory == 2
                        ? "判断"
                        : lb.QuestionCategory == 3
                        ? "填空"
                        : lb.QuestionCategory == 4
                        ? "简答"
                        : "论述"
                    }}
                  </span>
                  <span
                    v-if="
                      lb.QuestionCategory == 0 ||
                      lb.QuestionCategory == 1 ||
                      lb.QuestionCategory == 2
                    "
                    :class="
                      lb.StudentAnswerStr != lb.AnswerStr ? 'details_sign' : ''
                    "
                    >题目{{ b + 1 }}：{{ lb.QuestionTitle }}</span
                  >
                  <span v-else>题目{{ b + 1 }}：{{ lb.QuestionTitle }}</span>
                </p>
              </div>
              <el-radio-group
                v-if="lb.QuestionCategory === 0"
                v-model="lb.StudentAnswerStr"
                disabled
              >
                <p v-for="(lbb, p) in lb.BankItem" :key="p">
                  <el-radio :label="lbb.ItemNum"
                    >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                  >
                </p>
                <el-button
                  type="primary"
                  size="mini"
                  round
                  v-if="lb.parsingShow == false"
                  @click="parsClick(lb)"
                  >查看答案及解析</el-button
                >
                <div v-if="lb.parsingShow == true" class="js">
                  <div>
                    【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}
                  </div>
                  <div>
                    【解析】:
                    <span
                      v-html="
                        lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                      "
                    ></span>
                  </div>
                  <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
                </div>
              </el-radio-group>
              <el-checkbox-group
                v-else-if="lb.QuestionCategory === 1"
                v-model="lb.StudentAnswerStr"
                disabled
              >
                <p v-for="(lbb, p) in lb.BankItem" :key="p">
                  <el-checkbox :label="lbb.ItemNum"
                    >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-checkbox
                  >
                </p>
                <el-button
                  type="primary"
                  size="mini"
                  round
                  v-if="lb.parsingShow == false"
                  @click="parsClick(lb)"
                  >查看答案及解析</el-button
                >
                <div v-if="lb.parsingShow == true" class="js">
                  <div>
                    【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}
                  </div>
                  <div>
                    【解析】:
                    <span
                      v-html="
                        lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                      "
                    ></span>
                  </div>
                  <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
                </div>
              </el-checkbox-group>
              <el-radio-group
                v-else-if="lb.QuestionCategory === 2"
                v-model="lb.StudentAnswerStr"
                disabled
              >
                <p v-for="(lbb, p) in lb.BankItem" :key="p">
                  <el-radio :label="lbb.ItemNum"
                    >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                  >
                </p>
                <el-button
                  type="primary"
                  size="mini"
                  round
                  v-if="lb.parsingShow == false"
                  @click="parsClick(lb)"
                  >查看答案及解析</el-button
                >
                <div v-if="lb.parsingShow == true" class="js">
                  <div>
                    【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}
                  </div>
                  <div>
                    【解析】:
                    <span
                      v-html="
                        lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                      "
                    ></span>
                  </div>
                  <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
                </div>
              </el-radio-group>
              <div v-else-if="lb.QuestionCategory === 3">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入填空,多个填空以中文；隔开"
                  v-model="lb.StudentAnswerStr"
                  disabled
                >
                </el-input>
                <el-button
                  type="primary"
                  size="mini"
                  round
                  v-if="lb.parsingShow == false"
                  @click="parsClick(lb)"
                  >查看答案及解析</el-button
                >
                <div v-if="lb.parsingShow == true" class="js">
                  <div>
                    【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}
                  </div>
                  <div>
                    【解析】:
                    <span
                      v-html="
                        lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                      "
                    ></span>
                  </div>
                  <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
                </div>
              </div>
              <div v-else-if="lb.QuestionCategory === 4">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入简答"
                  v-model="lb.StudentAnswerStr"
                  disabled
                >
                </el-input>
                <el-button
                  type="primary"
                  size="mini"
                  round
                  v-if="lb.parsingShow == false"
                  @click="parsClick(lb)"
                  >查看答案及解析</el-button
                >
                <div v-if="lb.parsingShow == true" class="js">
                  <div>
                    【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}
                  </div>
                  <div>
                    【解析】:
                    <span
                      v-html="
                        lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                      "
                    ></span>
                  </div>
                  <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
                </div>
              </div>
              <div v-else-if="lb.QuestionCategory === 5">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入论述"
                  v-model="lb.StudentAnswerStr"
                  disabled
                >
                </el-input>
                <el-button
                  type="primary"
                  size="mini"
                  round
                  v-if="lb.parsingShow == false"
                  @click="parsClick(lb)"
                  >查看答案及解析</el-button
                >
                <div v-if="lb.parsingShow == true" class="js">
                  <div>
                    【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}
                  </div>
                  <div>
                    【解析】:
                    <span
                      v-html="
                        lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis
                      "
                    ></span>
                  </div>
                  <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
                </div>
              </div>
            </div>
          </van-list>
          <div v-if="list.length <= 0">
            <no-data></no-data>
          </div>
        </div>
      </div>
    </div>
    <div class="rollUp" v-if="practicesIndex > 4">
      <div class="rollUp_cont" style="padding: 10px 0">
        <div v-if="list.length > 0 && practicesIndex == 5">
          <el-row
            style="padding: 0; line-height: 48px"
            type="flex"
            justify="center"
            v-for="(li, i) in list"
            :key="i"
            @click.native="parcticeClickAfter(li)"
          >
            <el-col :span="16" style="padding-left: 10px">
              {{ li.CategoryName }}
              （
              <span class="c_999999">
                {{ li.DoQuestionCount }}
                <!-- /{{ li.AllQuestionCount }} -->
              </span>
              ）
            </el-col>
            <el-col :span="8" class="text_align_right">
              <img src="@/assets/images/icon_per.png" width="18" alt="" />
            </el-col>
          </el-row>
        </div>
        <div class="rollUp_cont_first" v-if="practicesIndex == 6">
          <div class="rollUp_cont_first_top">
            <div
              :class="masterIndex == i ? ' act' : ''"
              v-for="(m, i) in masterState"
              :key="i"
              @click="masterClick(i)"
            >
              {{ m }}
            </div>
            <div
              style="height: 10px; margin: 0 -15px; background-color: #f5f5f5"
            ></div>
          </div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="postQueryWordsBook"
            v-if="list.length > 0"
          >
            <ul>
              <li v-for="(li, i) in list" :key="i" @click="newWordBook(li)">
                <div class="name">
                  <span>{{ li.Name }}</span>
                  <span>{{ li.WordsCategoryName }}</span>
                </div>
                <div class="pronunciation">
                  {{ li.Phonetic }}
                  <img
                    src="@/assets/images/icon_audio.png"
                    class="icon_audio"
                    alt=""
                    srcset=""
                    @click="play(li.Name)"
                  />
                </div>
                <!-- <p class="notes" v-html="li.Explain"></p>-->
                <p class="time">{{ li.CreateTime }}</p>
                <div
                  style="
                    height: 10px;
                    margin: 0 -15px;
                    background-color: #f5f5f5;
                  "
                ></div>
              </li>
            </ul>
          </van-list>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="list.length > 0 && practicesIndex == 7">
          <el-row
            style="padding: 0; line-height: 48px"
            type="flex"
            justify="center"
            v-for="(li, i) in list"
            :key="i"
            @click.native="parcticeClickPattern(li)"
          >
            <el-col :span="16" style="padding-left: 10px">
              {{ li.CategroyName }}
              （
              <span class="c_999999">
                {{ li.DoCount }}
                <!-- /{{ li.AllCount }}  -->
              </span>
              ）
            </el-col>
            <el-col :span="8" class="text_align_right">
              <img src="@/assets/images/icon_per.png" width="18" alt="" />
            </el-col>
          </el-row>
        </div>
        <!-- <div v-else>
          <el-row>
            <el-col :span="24">
              <no-data></no-data>
            </el-col>
          </el-row>
        </div> -->
      </div>
    </div>
    <audio ref="audio"></audio>
  </div>
</template>

<script>
import {
  questionBankQueryQuestionCourse,
  questionBankQueryUserChapterRecord,
  questionBankQueryUserPaperRecord,
  questionBankQueryUserWordsByAnswers,
  questionBankQueryStudentWrongRecordList,
} from "@/api/personal";
import {
  queryPracticeInfo,
  querySentenceCategory,
  queryWordsBook,
} from "@/api/questionBank";
import noData from "@/components/noData";
export default {
  data() {
    return {
      route: {},
      list: [],
      courseList: [],
      selectVal: "",
      courseId: 0,
      courseActive: "true",
      practicesIndex: 1,
      practices: [
        { id: 1, name: "练习记录", icon: "el-icon-tickets" },
        { id: 2, name: "考试记录", icon: "el-icon-reading" },
        { id: 3, name: "刷题记录", icon: "el-icon-document-checked" },
        { id: 4, name: "错题记录", icon: "el-icon-document-delete" },
        { id: 5, name: "课后练习", icon: "el-icon-tickets" },
        { id: 6, name: "生词本", icon: "", img: "icon_en" },
        { id: 7, name: "句式分析", icon: "", img: "icon_en1" },
      ],
      page: 1,
      pageSize: 10,
      paperType: "",
      answer: "0",
      tabsList: [
        { name: "0", label: "熟悉" },
        { name: "1", label: "不熟悉" },
        { name: "2", label: "陌生" },
      ],
      customColor: "#3A78F9",
      loading: false,
      finished: false,
      type: 1, //1 练习记录 2考试记录 3刷题记录
      masterIndex: 0,
      masterState: ["认识", "不认识", "模糊"],
      isWxApplets: true,
    };
  },
  components: {
    noData,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "练习记录";
      this.isWxApplets = false;
    }
    this.route = this.$route.query;
    this.init();
  },
  mounted() {},
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    //播放词语
    play(name) {
      this.$refs.audio.src = "https://dict.youdao.com/speech?audio=" + name;
      this.$refs.audio.play();
    },
    // 生词本跳转
    newWordBook(obj) {
      let ob = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
        title: "生词本",
        Id: obj.Id,
        type: 3,
      };
      this.goVocabulary(ob);
    },
    goVocabulary(obj) {
      let url = "/H5/questionCenter/vocabulary";
      this.$router.push({ path: url, query: obj });
    },
    masterClick(i) {
      this.list = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.masterIndex = i;
      this.postQueryWordsBook();
    },
    // 生词本
    async postQueryWordsBook() {
      let parm = {
        studentId: this.route.UserID,
        courseId: this.myCourseId,
        pageIndex: this.page,
        pageSize: this.pageSize,
        answer: this.masterIndex,
      };
      const res = await queryWordsBook(parm);
      if (res.success == true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      }
    },
    // 句式分析跳转
    parcticeClickPattern(obj) {
      let path = "/H5/practice/translate";
      let ob = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
        CategroryId: obj.CategroryId,
        type: 0,
        exercises: 1,
      };
      this.$router.push({ path: path, query: ob });
    },
    // 词汇与语法跳转
    parcticeClickAfter(obj) {
      let path = "",
        ob = {};
      ob = {
        UserID: this.route.UserID,
        courseId: this.myCourseId,
      };
      if (obj.QuestionCategory == -1) {
        path = "/H5/practice/lexicalGrammar";
        ob.exercises = 1;
      } else if (obj.QuestionCategory == 1) {
        path = "/H5/practice/translate";
        ob.type = 1;
        ob.exercises = 1;
      } else if (obj.QuestionCategory == 2) {
        path = "/H5/practice/writing";
        ob.type = 2;
        ob.exercises = 1;
      }
      this.$router.push({ path: path, query: ob });
    },
    // 句式分析分类
    async getQuerySentenceCategory() {
      let parm =
        "?courseId=" + this.myCourseId + "&studentId=" + this.route.UserID;
      const res = await querySentenceCategory(parm);
      if (res.success == true) {
        this.list = res.response;
      }
    },
    // 课后练习
    async getQueryPracticeInfo() {
      let parm =
        "?courseId=" + this.myCourseId + "&studentId=" + this.route.UserID;
      const res = await queryPracticeInfo(parm);
      if (res.success == true) {
        this.list = res.response;
      }
    },
    //查看答案及解析
    parsClick(obj) {
      obj.parsingShow = true;
    },
    //刷题记录类型
    contactsClick(obj, i) {
      this.list = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.contactsIndex = i;
      this.answer = obj.name;
      this.getQuestionBankQueryUserWordsRecord();
    },
    // 查看详情
    gotoAnalysis(item) {
      if (this.courseActive == "false") {
        this.$message.error("对不起，当前课程不在有效期内，不能查看");
        return false;
      }
      console.log(item);
      // H5/topicPaper?Id=15&Name=2021《文艺理论》直播课&UserID=65
      let path = "";
      let query = { DataId: item.DataId };
      if (this.type == 1) {
        path = "/H5/practiceRecordDetails";
      } else if (this.type == 2) {
        path = "/H5/examRecordDetails";
      } else {
        path = "/H5/topicPaper";
        query = {
          Id: item.Id,
          Name: item.Name,
          UserID: this.$route.query.UserID,
          CourseId: item.CourseId,
          WordId: item.Id,
          Answer: this.answer,
          questionCategory: -1,
        };
      }
      this.$router.push({ path, query });
    },
    handleChange(obj) {
      // console.log(obj);
      // console.log(this.practicesIndex);
      // this.courseId = value.split(',')[0];
      // this.courseActive = value.split(',')[1];
      this.list = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.courseId = obj.CourseId;
      this.myCourseId = obj.CourseId;
      this.selectVal = obj.CourseName;
      this.courseActive = obj.IsActive;
      if (obj.CourseName.includes("英语")) {
        this.practicesIndex = 5;
      } else {
        this.practicesIndex = 1;
      }
      this.practicesType();
    },
    //练习模式
    practicesClick(obj) {
      // if(this.user == null){
      //   this.$message.error("请先登陆");
      //   return false;
      // }
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.practicesIndex = obj.id;
      this.type = obj.id;
      this.contactsIndex = 0;
      this.list = [];
      this.practicesType();
    },
    practicesType() {
      if (this.practicesIndex == 1) {
        this.getQuestionBankQueryUserChapterRecord();
      } else if (this.practicesIndex == 2) {
        this.getQuestionBankQueryUserPaperRecord();
      } else if (this.practicesIndex == 3) {
        this.getQuestionBankQueryUserWordsRecord();
      } else if (this.practicesIndex == 4) {
        this.getQuestionBankQueryWrongRecord();
      } else if (this.practicesIndex == 5) {
        this.getQueryPracticeInfo();
      } else if (this.practicesIndex == 6) {
        this.postQueryWordsBook();
      } else if (this.practicesIndex == 7) {
        this.getQuerySentenceCategory();
      }
    },
    // 练习记录
    async getQuestionBankQueryUserChapterRecord() {
      let parm =
        "?courseId=" +
        this.courseId +
        "&studentId=" +
        this.route.UserID +
        "&pageIndex=" +
        this.page +
        "&pageSize=" +
        this.pageSize;
      const res = await questionBankQueryUserChapterRecord(parm);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 考试记录
    async getQuestionBankQueryUserPaperRecord() {
      let parm =
        "?courseId=" +
        this.courseId +
        "&studentId=" +
        this.route.UserID +
        "&pageIndex=" +
        this.page +
        "&pageSize=" +
        this.pageSize;
      const res = await questionBankQueryUserPaperRecord(parm);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 刷题记录
    async getQuestionBankQueryUserWordsRecord() {
      let parm = {
        courseId: this.courseId,
        studentId: this.route.UserID,
        pageIndex: this.page,
        pageSize: this.pageSize,
        answer: this.answer,
      };
      const res = await questionBankQueryUserWordsByAnswers(parm);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 错题记录
    async getQuestionBankQueryWrongRecord() {
      let parm = {
        courseId: this.courseId,
        studentId: this.route.UserID,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await questionBankQueryStudentWrongRecordList(parm);
      if (res.success === true) {
        res.response.data.forEach((it) => {
          if (it.QuestionCategory == 1) {
            it.AnswerStr = it.AnswerStr.split("");
            it.StudentAnswerStr = it.StudentAnswerStr.split(",");
          }
          this.$set(it, "parsingShow", false);
        });
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    //前去练习列表
    gotoList(id) {
      if (!this.courseId) {
        this.$message({
          message: "请选择课程",
          type: "warning",
        });
        return;
      }
      this.$router.push({
        path: "/H5/practiceList",
        query: {
          CourseId: this.courseId,
          isBrush: id == 3 ? 1 : 0,
          type: id,
          UserID: this.route.UserID,
          courseActive: this.courseActive,
        },
      });
    },
    async init() {
      let parm = {
        studentId: this.route.UserID,
      };
      const res = await questionBankQueryQuestionCourse(parm);
      if (res.success === true) {
        this.courseList = res.response;
        this.courseId = this.courseList[0].CourseId;
        this.selectVal = this.courseList[0].CourseName;
        this.courseActive = this.courseList[0].IsActive;
        this.practicesType();
        // console.log(this.selectVal);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.practice {
  /deep/ .el-input__inner {
    border: 0;
  }
  .Icons {
    background: #fff;
    display: flex;
    margin-top: 10px;
    border-radius: 5px;
    justify-content: space-evenly;
    width: 90%;
    margin-left: 5%;
    div {
      display: flex;
      flex-direction: column;
      width: 100px;
      height: 100px;
      justify-content: space-evenly;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .top {
    width: 100%;
    min-height: 153px;
    background: url("../../../assets/images/bg_h_pic.png");
    background-repeat: no-repeat;
    background-size: 100%;
    .sliding_box {
      padding: 20px 10px;
      ul {
        display: flex;
        li {
          margin-right: 10px;
          display: inline-block;
          min-width: 115px;
          height: 24px;
          line-height: 24px;
          padding: 0 10px;
          border-radius: 20px;
          flex-shrink: 0;
          color: #fff;
        }
        .active {
          background: #ffffff;
          color: #3a78f9;
        }
      }
    }
  }
  .practice {
    overflow: hidden;
    background-color: #fff;
    margin: 0 10px;
    border-radius: 10px;
    li {
      text-align: center;
      width: 25%;
      float: left;
      padding: 25px 0 20px;
      div {
        width: 56px;
        height: 56px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        border-radius: 50%;
      }
      i {
        font-size: 24px;
      }
      p {
        margin-top: 10px;
      }
      .c_707070 {
        color: #707070;
      }
      .c_3A78F9 {
        color: #3a78f9;
      }
      .bgc_E2EBFE {
        background-color: #e2ebfe;
      }
      .active {
        color: #1288f4;
      }
    }
  }
  .rollUp {
    .rollUp_top {
      padding: 0 10px;
      margin: 10px 0;
      background-color: #fff;
      color: #5b5b5b;
      .li_box {
        display: flex;
      }
      span {
        display: inline-block;
        min-width: 70px;
        text-align: center;
        margin-right: 10px;
        line-height: 60px;
        cursor: pointer;
      }
      .active {
        color: #1288f4;
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 3px;
          background-color: #1288f4;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
    .rollUp_cont_first {
      .rollUp_cont_first_top {
        font-size: 15px;
        height: 45px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #fff;
        margin-top: 10px;
        .act {
          color: #3a78f9;
          position: relative;
          &::before {
            display: inline-block;
            content: " ";
            width: 20px;
            height: 3px;
            background: #3a78f9;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -10px;
          }
        }
      }
      ul {
        li {
          padding: 10px 15px;
          background-color: #fff;
          // margin-top: 8px;
          .name {
            font-size: 16px;
            font-family: DIN;
            font-weight: bold;
            color: #3a78f9;
            line-height: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              &:nth-child(2) {
                font-size: 14px;
                color: #999999;
              }
            }
          }
          .pronunciation {
            line-height: 1.5;
            margin: 10px 0 10px;
          }
          .notes {
            line-height: 1.5;
            word-break: break-all;
          }
          .time {
            font-size: 13px;
            color: #999999;
            line-height: 1;
            margin: 10px 0;
          }
          .icon_audio {
            width: 15px;
            vertical-align: text-bottom;
            margin: 0 5px;
          }
        }
      }
    }
    .rollUp_cont {
      // padding: 0 40px;
      // padding: 20px 0;
      margin: 10px 0;
      background-color: #fff;
      .el-tree {
        padding: 0 10px;
        .custom-tree-node {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          padding-right: 8px;
          .tree_right {
            display: flex;
            /deep/.el-progress {
              width: 120px;
              .el-progress-bar {
                padding-right: 5px !important;
              }
              .el-progress__text {
                display: none;
              }
            }
            .proportion {
              min-width: 45px;
              text-align: right;
            }
          }
        }
      }
      .el-row {
        line-height: 40px;
        border-bottom: 1px solid #f5f5f5;
        padding: 15px 0;
        .el-col {
          cursor: pointer;
          &:first-child {
            padding-left: 10px;
          }
          &:last-child {
            padding-right: 10px;
            text-align: right;
          }

          /deep/.explain {
            color: #a1a1a1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          /deep/.explain > * {
            display: none;
          }
          /deep/.explain p:nth-child(1) {
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .name {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            line-height: 20px;
          }
          .count {
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            margin-top: 6px;
          }
          .count_pbf {
            margin-top: 5px;
          }
          /deep/.el-progress {
            width: 140px;
            .el-progress__text {
              display: none;
            }
          }
          .el-button {
            background-color: #558af9;
          }
        }
      }
    }

    .list {
      margin: 10px 0;
      .li {
        .js {
          font-size: 14px;
          line-height: 175%;
        }
        .title {
          background-color: #fff;
          margin-bottom: 10px;
          font-size: 14px;
          color: #999999;
        }
        .paperBank {
          margin-bottom: 5px;
          padding: 20px 30px;
          background-color: #fff;
          .questionTitle {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            .correction {
              margin-left: 5px;
              color: #c5c5c5;
              font-size: 12px;
              width: 40px;
              cursor: pointer;
            }
            p {
              line-height: 22px;
              // height: 22px;
            }
            .tmtype {
              flex: 1;
              span {
                &:first-child {
                  display: inline-block;
                  background-color: #3a78f9;
                  line-height: 22px;
                  height: 22px;
                  color: #fff;
                  padding: 0 8px;
                  border-radius: 20px;
                  margin-right: 10px;
                  font-size: 12px;
                }
              }
            }
            .tmmc {
              margin-top: 10px;
            }
          }
          .el-radio-group,
          .el-checkbox-group {
            .el-checkbox {
              // display: flex;
              // align-items: center;
            }
            .el-radio,
            .el-radio__input,
            .el-checkbox,
            .el-checkbox__input {
              white-space: normal !important;
              line-height: 175%;
            }
            p {
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .el-textarea {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.details_sign {
  color: #f61717 !important;
}
/deep/.el-scrollbar {
  .el-scrollbar__bar {
    display: none;
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
